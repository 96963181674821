import chasseur from '../img/chasseur.png'
import chevreuil from '../img/chevreuil.png'
import chien from '../img/chien.png'
import ecolo from '../img/ecolo.png'
import femme from '../img/femme.png'
import lapin from '../img/lapin.png'
import sanglier from '../img/sanglier.jpg'
import {ImageType} from "../App";


export const Game = ({blur, toastMessage, bandw, shot, score, alcool, image}: {
    toastMessage?: string,
    blur: number,
    aleat: number,
    bandw: boolean,
    score: number,
    alcool: number,
    image: ImageType
    shot: (shotOrNot: boolean) => void
}) => {

    // Random boolean
    const random = Math.floor(Math.random() * 100) > 50
    const randomColorShoot = Math.floor(Math.random() * 100) > 50
    const randomColorNoShoot = Math.floor(Math.random() * 100) > 50

    let imgToDisplay;
    switch (image) {
        case "chasseur":
            imgToDisplay = chasseur
            break;
        case "chevreuil":
            imgToDisplay = chevreuil
            break;
        case "chien":
            imgToDisplay = chien
            break;
        case "ecolo":
            imgToDisplay = ecolo
            break;
        case "femme":
            imgToDisplay = femme
            break;
        case "lapin":
            imgToDisplay = lapin
            break;
        case "sanglier":
            imgToDisplay = sanglier
            break;
    }

    return (
        <div className={'App'}>
            <div> Score : {score} | alcoolémie : {alcool} g/L</div>
            <div className={'toast'}> {toastMessage} </div>
            <img style={{filter: `blur(${blur}px) hue-rotate(${blur * 10}deg)`, maxWidth: `${400 - blur * 10}px`}}
                 className={'pict slide-top'} src={imgToDisplay} alt={imgToDisplay}/>
            <div>
                {random &&
                    <button className={'button-3'} style={{backgroundColor: randomColorShoot ? 'red' : '#2ea44f'}}
                            onClick={() => shot(true)}>Tirer</button>}
                <button className={'button-3'} style={{backgroundColor: randomColorNoShoot ? 'red' : '#2ea44f'}}
                        onClick={() => shot(false)}>Ne pas tirer
                </button>
                {!random &&
                    <button className={'button-3'} style={{backgroundColor: randomColorShoot ? 'red' : '#2ea44f'}}
                            onClick={() => shot(true)}>Tirer</button>}
            </div>
        </div>
    )
}