import React, {useEffect} from 'react';
import './App.css';
import {Difficulty} from "./components/Difficulty";
import {Game} from "./components/Game";
import {getNewImg} from "./util";

import logo from './img/logo.png'



const GOOD_POINT = 100;
const BAD_POINT = 10;


export type DifficultyType = 'normal' | 'real'

export type ImageType = 'sanglier' | 'chevreuil' | 'lapin' | 'ecolo' | 'chien' | 'chasseur' | 'femme'

const getGrade = (score: number) => {
  if(score < 0){
    return 'Chasseur spontané'
  }
  if(score < 1000){
      return 'Ami de la nature'
  }
  return 'Protecteur de la forêt'
}

function App() {

  const [difficulty, setDifficulty] = React.useState<DifficultyType|undefined>(undefined)
  const [stage, setStage] = React.useState<number>(0)
  const [score, setScore] = React.useState<number>(0)
  const [aleat, setAleat] = React.useState<number>(0)
  const [blur, setBlur] = React.useState<number>(0)
  const [bAndW, setBAndW] = React.useState<boolean>(false)
  const [toast, setToast] = React.useState<string|undefined>('Un petit verre pour la route ?')
  const [nbrEcolo, setNbrEcolo] = React.useState<number>(0)
  const alcool = (stage* (difficulty==='real'?3:1)/10)

  const [image, setImage] = React.useState<ImageType>('sanglier')

  const sendToast = (message: string)=>{
    setToast(message)
  }

  const shot = (shotOrNot: boolean)=>{
    const random = Math.floor(Math.random() * 100)
    if(random < aleat && (image === 'chasseur' || image === 'femme' || image === 'chien') && ! shotOrNot){
      sendToast('Oupsi vous avez malgré tout tiré par mégarde, mais un verre pour oublier')
    }else{
      if(shotOrNot){
        if(image === 'sanglier'|| image === 'chevreuil' || image === 'lapin'){
          sendToast('Bravo, allé un pti verre pour fêter ça')
          setScore(score+GOOD_POINT)
        }else if(image==='ecolo') {
          sendToast('Bravo, un écolo de moins, allé un pti verre pour fêter ça')
          setNbrEcolo(nbrEcolo+1)
          setScore(score+GOOD_POINT)
        }else{
          sendToast('Oupsi mauvais tir, mais un verre pour oublier')
          setScore(score-BAD_POINT)
        }
      }else{
        if(image === 'sanglier'|| image === 'chevreuil' || image === 'lapin' || image === 'ecolo'){
          sendToast('Ah mince le gibier est parti, mais un verre pour oublier')
          setScore(score-GOOD_POINT)
        }else{
          sendToast('Bien vu, allé un pti verre pour fêter ça')
          setScore(score+BAD_POINT)
        }
      }
    }
    setStage(stage+1)
  }

  useEffect(()=>{
    // Choose a random image
   const newImg = getNewImg(image);
    setImage(newImg)

    if(difficulty === 'normal'){
      setBlur(blur+0.2)
      setAleat(aleat+2)
    }else if(difficulty === 'real'){
      setBlur(blur+0.4)
      setAleat(aleat+4)
    }
  }, [stage])

  if(!difficulty){
    return <Difficulty setDifficulty={setDifficulty}/>
  }

  if(stage < 50) {
    return <>
      <Game blur={blur} aleat={aleat} bandw={bAndW} shot={shot} score={score} alcool={alcool} image={image}
            toastMessage={toast}/>
    </>
  }

  return <>
    <div className={'App'}>
      <h4>Félicitation vous obtenez le grade :</h4>
      <h1>{getGrade(score)}</h1>
      <h4> Score : {score}</h4>
      <h4> Nombre d'écolo éliminés : {nbrEcolo}</h4>
      <br/>

      <img className={'logo'} src={logo}/>
    </div>
  </>


}

export default App;
