import {ImageType} from "./App";


export const getRandomImg = () => {
    const random = Math.floor(Math.random() * 7)
    switch (random) {
        case 0:
            return 'sanglier'
            break;
        case 1:
            return 'chevreuil'
            break;
        case 2:
            return 'lapin'
            break;
        case 3:
            return 'ecolo'
            break;
        case 4:
            return 'chien'
            break;
        case 5:
            return 'chasseur'
            break;
        case 6:
            return 'femme'
            break;
        default:
            return 'sanglier'
            break;
    }
}

export const getNewImg = (existing: ImageType): ImageType => {
    const newImg = getRandomImg()
    if(existing === newImg) {
        return getNewImg(existing)
    }
    return newImg
}