import {DifficultyType} from "../App";
import logo from "../img/logo.png";
import React from "react";


export const Difficulty = ({setDifficulty}: {setDifficulty: (dif: DifficultyType)=>void}) => {
    return (
        <div className={'App'}>
            <h1>Prévention chasse</h1>
            <p>Ce petit jeu pédagogique a pour objectif de sensibiliser sur les difficultés à appréhender la nature
                d'une cible lors d'une partie de chasse</p>
            <p>Vous ne devez tirer que sur les annimaux pour marquer des points</p>
            <h1>Choisissez votre difficulté de jeu</h1>
            <div>
                <button className={'button-3'} onClick={() => setDifficulty('normal')}>Normal 🍷</button>
                <button className={'button-3'} onClick={() => setDifficulty('real')}>Réel 🍷🍷🍷🍷🍷🍷🍷🍷</button>
            </div>
            <br/>
            <img className={'logo'} src={logo}/>

        </div>
    )
}